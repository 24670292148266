import * as React from "react";
import PatientLayout from "../components/patient/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../components/seo";

const ContactUsPage = () => {
  return (
    <PatientLayout pageClass="patient-contact-us">
      <section className="btm-spacer">
        <Row>
          <Col xs={12}>
            <h1 className="first interior-first">Contact Us</h1>
            <Row>
              <Col xs={6} md={3}>
                <p className="pb-15">Main Phone Number</p>
                <p className="pb-15">Medical Inquiries/Drug Safety</p>
                <p className="pb-15">Customer Service</p>
                <p className="pb-15">Ethics and Compliance Hotline</p>
              </Col>
              <Col xs={6} md={9} style={{ paddingLeft: '0px' }}>
                <p className="pb-15"><a href="tel:+12035888000">1-203-588-8000</a></p>
                <p className="pb-15"><a href="tel:+18887267535">1-888-726-7535</a></p>
                <p className="pb-15"><a href="tel:+18008775666">1-800-877-5666</a></p>
                <p className="pb-15"><a href="tel:+18777873831">1-877-PURDUE1</a> (or <a href="tel:+18777873831">1-877-787-3831</a>) toll free, 24 hours a day, to report any suspected violations of ethics, law or Purdue policy. Callers may choose to remain anonymous and will be protected from retaliation in any form. Alternatively, callers may email Purdue Ethics &amp; Compliance at <a href="mailto:compliance@pharma.com">compliance@pharma.com</a>.</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </PatientLayout>
  );
};

export default ContactUsPage;

export const Head = () => <Seo title="Contact Us" description="Find information on contacting the makers of Hysingla ER Extended-Release Tablets CII. See Full PI, Medication Guide & Boxed Warning." />;
